import React from 'react'

import { Controller, useForm } from 'react-hook-form';
import Multiselect from 'react-widgets/Multiselect';
import 'react-widgets/styles.css';
type MultiSelectProps = {
  name: string;
  control: any;
  options: any[];
  label?: string;
  error?:string;
  helpText?:string;
  isLoading?:boolean;
  onChangSelected?: (selected:any) => void;
  onBlurSelected?: (selected:any) => void;
}
function MultiSelectInput(props:MultiSelectProps) {


  return (
    <>
{props.label
&&
<label htmlFor={props.name} 
className='inline-block mb-1.5 whitespace-nowrap font-semibold'
>
  {props.label}
</label>}
{props.isLoading ? 
'...loading'
:(
    <Controller
      name={props.name}
      control={props.control}
      render={({ field }) =>{ 
        return(
        <Multiselect
          {...field}
          data={props.options}
          value={field.value}
          onChange={field.onChange}
          onBlur={field.onBlur}
          textField="name"
          dataKey={'id'}
        />
      )}}
    />
  )}
    {props.error && <div className='text-red-500 text-sm'>{props.error}</div>}
    {props.helpText && <div className="text-gray-400">{props.helpText}</div>}
    </>
  );
};

export default MultiSelectInput