import classNames from 'classnames'
import { DetailedHTMLProps, forwardRef, ReactNode, TextareaHTMLAttributes } from 'react'

type Props = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
   label?: string | ReactNode
   helpText?: string | ReactNode
   showErrorText?: boolean
   containerClassName?: string
   error?: string
   isTouched?: boolean
}

export const TextArea = forwardRef<HTMLTextAreaElement, Props>(
   ({ name, label, helpText, rows, error, showErrorText, isTouched, containerClassName, ...rest }, ref) => {
      return (
         <div
            className={classNames(containerClassName, {
               'has-error': error,
               'has-success': !error && isTouched,
            })}
         >
            {label && (
               <label htmlFor={name} className={'inline-block mb-2'}>
                  {label}
               </label>
            )}
            <textarea ref={ref} name={name} className={'form-control'} rows={rows} {...rest} />
            {helpText && <small className="text-gray-400 block mb-1.5">{helpText}</small>}
            {error && showErrorText && <div className="error-block text-sm mt-2">{error}</div>}
         </div>
      )
   },
)

TextArea.defaultProps = {
   rows: 2,
   showErrorText: true,
}
