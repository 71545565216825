import classNames from 'classnames'
import { Button } from '../../Button'

type Props = {
   pageIndex: number
   pageCount: number
   previousPage: () => void
   nextPage: () => void
   gotoPage: (index: number) => void
}

const Pagination = ({ pageIndex, pageCount, previousPage, nextPage, gotoPage }: Props) => {
   let activePage = pageIndex
   let totalPage = pageCount
   const canPreviousPage = pageIndex > 0
   const canNextPage = pageIndex < pageCount - 1

   const pageNumber = (total: number, max: number, current: number) => {
      const half = Math.round(max / 2)
      let to = max
      if (current + half >= total) {
         to = total
      } else if (current > half) {
         to = current + half
      }
      let from = to - max
      return Array.from({ length: max }, (_, i) => i + from)
   }

   const drawOptions = () => {
      let max = totalPage > 10 ? 5 : totalPage
      let pageOptions = pageNumber(totalPage, max, activePage)

      return pageOptions.map((i, index) => {
         const active = activePage === index

         return (
            <Button
               key={index}
               className={classNames({
                  '!bg-blue-500 !text-white !border-blue-500': active,
               })}
               onClick={() => gotoPage(i)}
            >
               {i + 1}
            </Button>
         )
      })
   }
   return (
      <Button.Group spaced={false}>
         <Button onClick={previousPage} disabled={!canPreviousPage}>
            &lt;
         </Button>
         {drawOptions()}
         <Button onClick={nextPage} disabled={!canNextPage}>
            &gt;
         </Button>
      </Button.Group>
   )
}

export default Pagination
