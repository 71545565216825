import { HotelFlightFormType, HotelFormType } from "booker"
import { Theme } from "utils/layout"
import { stageData } from './data.siteConfig';
import { ServiceFormType } from "booker/src/partials/Services/ServiceForm";

export type SelectedForm = 'HOTEL_FLIGHT' | 'HOTEL' | 'SERVICES' | null

export type Account = {
   code: string
   siteUrl: string
   languages: Array<{
      language: string
      siteUrl: string
   }>
}

export type BookerPlace = {
   id: number
   name: string
   place: {
      id: number
      name: string
      airportCode?: string
   },
   chain?: {
      id: number
      name: string
   },
   url?: string
}

export type SiteConfig = {
   language: string
   account: Account
   dateFormat: string
   showCalendarIcon?: boolean
   selectedForm?: SelectedForm
   hotelForm?: HotelFormType
   hotelFlightForm?: HotelFlightFormType
   serviceForm?: ServiceFormType
   theme?: Theme
   apiUserName: string
   apiPassword: string
   bookerPlaces: Array<BookerPlace>
   externalHotels?: Array<BookerPlace>
   showStandaloneServices?: boolean
   currency: string
   submitOnSameTab?: boolean
}

export type SiteConfigRequest = {
   account: string
   locale: string
   channelId?: number
}

export async function getSiteConfig(request: SiteConfigRequest): Promise<SiteConfig> {
   const { account, locale, channelId } = request
   try {
      const setupApi = process.env.REACT_APP_PUBLIC_STATIC_SETUP_API
      if (setupApi && setupApi !== "") {
         const url = new URL(
            `${process.env.REACT_APP_PUBLIC_STATIC_SETUP_API}/siteConfig/static/${account}/lang/${locale}`
         );
         const params = [];
         if (channelId) {
            params.push(["channelId", channelId.toString()]);
         }
         url.search = new URLSearchParams(params).toString();
         const requestHeaders: HeadersInit = new Headers();
         requestHeaders.set('x-api-key', process.env.REACT_APP_PUBLIC_STATIC_SETUP_API_KEY || "");

         const data = await fetch(url, {
            method: "GET",
            headers: requestHeaders
         });
         return data.json()
      } else {
         const result = await new Promise((resolve) => {
            setTimeout(() => {
               const siteConfigs = stageData // JSON.parse(process.env.NEXT_PUBLIC_SITE_CONFIGS || '[]')
               const siteConfigList = siteConfigs as Array<SiteConfig>
               const result = siteConfigList.find((r) => {
                  return r.account.code === account
               })
               resolve({ data: result })
            }, 100)
         }) as { data: any }
         return result.data
      }
   } catch (error) {
      throw error
   }
}