import classNames from 'classnames'
import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, ReactNode } from 'react'

type Props = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
   label?: string | ReactNode
   helpText?: string | ReactNode
   showErrorText?: boolean
   className?: string
   inputGroup?: string | ReactNode
   inputGroupAling?: 'right' | 'left'
   width?: string
   containerClassName?: string
   error?: any
   isTouched?: boolean
   useTailwindPrefix?: boolean
   boldLabel?: boolean
}

export const Input = forwardRef<HTMLInputElement, Props>(
   (
      {
         id,
         name,
         label,
         helpText,
         width,
         className,
         error,
         showErrorText,
         inputGroup,
         inputGroupAling,
         containerClassName,
         isTouched,
         children,
         useTailwindPrefix,
         boldLabel,
         ...rest
      },
      ref,
   ) => {
      return (
         <div
            className={classNames(containerClassName, {
               'has-error': error,
               'has-success': !error && isTouched,
            })}
         >
            {label && (
               <label
                  htmlFor={name}
                  className={classNames({
                     'inline-block mb-1.5 whitespace-nowrap': !useTailwindPrefix,
                     'tw-inline-block tw-mb-1.5 tw-whitespace-nowrap': useTailwindPrefix,
                     'font-semibold': boldLabel && !useTailwindPrefix,
                     'tw-font-semibold': boldLabel && useTailwindPrefix,
                  })}
               >
                  {label}
               </label>
            )}
            <div className={`${inputGroup ? 'input-group' : ''}`}>
               {inputGroup && inputGroupAling === 'left' && <span className={'input-group-text'}>{inputGroup}</span>}
               <input
                  id={id || name}
                  ref={ref}
                  name={name}
                  className={classNames('form-control', className)}
                  {...(width && { style: { width } })}
                  {...rest}
               />
               {inputGroup && inputGroupAling === 'right' && <span className={'input-group-text'}>{inputGroup}</span>}
            </div>
            {helpText && (
               <small
                  className={classNames({
                     'text-gray-400 block my-1.5': !useTailwindPrefix,
                     'tw-text-gray-400 tw-block tw-my-1.5': useTailwindPrefix,
                  })}
               >
                  {helpText}
               </small>
            )}
            {error && showErrorText && (
               <div
                  className={classNames('error-block', {
                     'text-sm mt-2': !useTailwindPrefix,
                     'tw-text-sm tw-mt-2': useTailwindPrefix,
                  })}
               >
                  {error}
               </div>
            )}
            {children && children}
         </div>
      )
   },
)

Input.defaultProps = {
   inputGroup: null,
   inputGroupAling: 'left',
   showErrorText: true,
   boldLabel: true,
}
