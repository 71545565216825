import { faHotel, faPlane, faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import classNames from 'classnames'
import React, { createContext, ReactNode, useContext } from 'react'
import { useIntl } from 'react-intl'

const NavBarContext = createContext<NavBarType>({} as NavBarType)

export type service = 'hotel' | 'hotelFlight' | 'service' | null

type NavBarType = {
   selected: service
   onChange?: Function
   selectedClassName?: string
   useTailwindPrefix?: boolean
}

type Props = NavBarType & {
   children: ReactNode
   className?: string
}

type ItemProps = {
   children: ReactNode
   value: service
   className?: string
}

const Item = ({ children, value, className }: ItemProps) => {
   const { onChange, selected, selectedClassName, useTailwindPrefix } = useContext(NavBarContext)
   const onClick = () => {
      onChange && onChange(value)
   }
   return (
      <li
         className={classNames(className, selectedClassName && selected === value && `${selectedClassName}`, {
            'relative block': !useTailwindPrefix,
            'tw-relative tw-block': useTailwindPrefix,
         })}
      >
         <a
            className={classNames('itm-booker-navItem', {
               'relative block py-2 px-6 border-b-[3px] cursor-pointer': !useTailwindPrefix,
               'tw-relative tw-block tw-py-2 tw-px-6 tw-border-b-[3px] tw-cursor-pointer': useTailwindPrefix,
               'border-gray-300': selected === value && !useTailwindPrefix,
               'tw-border-gray-300': selected === value && useTailwindPrefix,
               'border-transparent': selected !== value && !useTailwindPrefix,
               'tw-border-transparent': selected !== value && useTailwindPrefix,
            })}
            onClick={onClick}
         >
            {children}
         </a>
      </li>
   )
}

const HotelItem = () => {
   const intl = useIntl()
   return (
      <Item value="hotel">
         <FontAwesomeIcon icon={faHotel} />
         &nbsp;
         {intl.formatMessage({ id: 'booker.hotel' })}
      </Item>
   )
}

const HotelFlightItem = () => {
   const intl = useIntl()
   return (
      <Item value="hotelFlight">
         <FontAwesomeIcon icon={faPlane} rotation={270} />
         &nbsp;
         {intl.formatMessage({ id: 'booker.hotel' })}&nbsp;+&nbsp;
         {intl.formatMessage({ id: 'booker.flight' })}
      </Item>
   )
}

const ServiceItem = () => {
   const intl = useIntl()
   return (
      <Item value="service">
         <FontAwesomeIcon icon={faStar} />
         &nbsp;
         {intl.formatMessage({ id: 'booker.services' })}
      </Item>
   )
}

export const NavBar = ({ children, onChange, selected, className, selectedClassName, useTailwindPrefix }: Props) => {
   return (
      <NavBarContext.Provider value={{ onChange, selected, selectedClassName, useTailwindPrefix }}>
         <ul
            className={classNames(className, {
               'mb-3.5 bg-transparent flex': !useTailwindPrefix,
               'tw-mb-3.5 tw-bg-transparent tw-flex': useTailwindPrefix,
            })}
         >
            {children}
         </ul>
      </NavBarContext.Provider>
   )
}
NavBar.Item = Item
NavBar.HotelItem = HotelItem
NavBar.HotelFlightItem = HotelFlightItem
NavBar.ServiceItem = ServiceItem
