import './App.css'
import 'ui/styles/form.css'
import BookerWrapper from './components/BookerWrapper'
import { useAppDispatch, useAppSelector } from './store/hooks'
import { useEffect } from 'react'
import { getSiteConfigThunk } from './features/siteConfigSlice'
import { getColors } from 'utils/layout'
import { Booker, NavBar } from 'booker'
import { isVertical } from './utils/siteConfigUtils'

type Props = {
   accountCode: string
   locale: string
   hideNavbar?: boolean
   dataOnlyFormToShow: 'HOTEL_FLIGHT' | 'HOTEL' | 'SERVICES' | null
   hotelId?: number
}

function App({ accountCode, locale, hideNavbar, dataOnlyFormToShow, hotelId }: Props) {
   const dispatch = useAppDispatch()
   const siteConfig = useAppSelector((state) => state.siteConfig)

   useEffect(() => {
      dispatch(getSiteConfigThunk({ account: accountCode, locale }))
   }, [dispatch, accountCode, locale])

   const colors = siteConfig.data?.theme ? getColors(siteConfig.data.theme) : null

   return (
      <>
         {colors && (
            <style>{`
            :root {
               ${colors.join(' ')}
            }
         `}</style>
         )}
         {siteConfig.isPristine || siteConfig.isFetching ? (
            <Booker vertical={isVertical()} locale={''} dateFormat={''} useTailwindPrefix>
               {!hideNavbar && (!dataOnlyFormToShow || dataOnlyFormToShow === 'HOTEL') && (
                  <NavBar selected="hotel" useTailwindPrefix>
                     <NavBar.HotelItem />
                  </NavBar>
               )}
               <Booker.Loading />
            </Booker>
         ) : siteConfig.data ? (
            <BookerWrapper siteConfig={siteConfig.data} dataOnlyFormToShow={dataOnlyFormToShow} hotelId={hotelId} />
         ) : null}
      </>
   )
}

export default App
