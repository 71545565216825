import React from 'react'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export type AmountProps = {
   className?: string
   amount: number
   showCurrency?: boolean
   showSign?: boolean
   currency?: string | null
   minimumFractionDigits?: number
   maximumFractionDigits?: number
   locale?: string
   showInfoIcon: boolean
   showCurrencySign: boolean
}

export const Amount = ({
   className,
   amount,
   showCurrency,
   showSign,
   currency,
   minimumFractionDigits,
   maximumFractionDigits,
   locale,
   showInfoIcon,
   showCurrencySign,
}: AmountProps) => {
   let formattedAmount = ''
   const currencyProps: Intl.NumberFormatOptions = {
      style: 'decimal',
      minimumFractionDigits: minimumFractionDigits,
      maximumFractionDigits: maximumFractionDigits,
   }

   try {
      if (Intl) {
         const formatter2 = new Intl.NumberFormat(locale, currencyProps)
         formattedAmount = formatter2.format(amount)
      }
   } catch {
      formattedAmount = '' + amount
   }
   return (
      <span {...(className && { className })}>
         <span>
            {showSign && amount >= 0 && <i>+</i>}
            {showCurrencySign ? '$' : ''}
            {formattedAmount}
         </span>
         {showCurrency && (
            <>
               &nbsp;
               {currency}
            </>
         )}
         {showInfoIcon && (
            <>
               &nbsp;
               <FontAwesomeIcon icon={faCircleInfo} />
            </>
         )}
      </span>
   )
}

Amount.defaultProps = {
   minimumFractionDigits: 0,
   maximumFractionDigits: 0,
   showCurrency: true,
   showInfoIcon: false,
   showSign: false,
   locale: 'en-US',
   showCurrencySign: true,
}
